import React from 'react'

const MenuPA = () => {
    return (
        <div className="container mx-auto">
            <section className="bg-[#F5F4EE] py-12 md:py-16 lg:py-20 text-[#121212] z-10">
                <div className="mx-auto py-16 md:py-16 lg:py-16">
                    <div className="text-center uppercase font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
                        Resources
                    </div>
                    <div className="text-center font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl mt-4">
                        Legal Resources
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MenuPA
