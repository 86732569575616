import React from 'react'
import ValueSection from './ValueSection'

const Values = () => {
    return (
        <>
            <div className="container mx-auto">
                <section className="container bg-[#FFFFFF] lg:py-20 md:py-12 sm:py-8 px-8">
                    <div className="md:p-6 sm:p-4 mx-auto">
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
                            <div className="py-4 sm:p-4 rounded-lg ">
                                <div className=" py-2">
                                    <h2 className="text-2xl lg:text-4xl md:text-3xl font-bold border-b-4 border-b-[#060C82] text-[#121212]">
                                        Our Values.
                                    </h2>
                                </div>
                            </div>
                            <div className="rounded-lg lg:col-span-2">
                                <div className=" space-x-6 py-2">
                                    <p className="text-xl">
                                        We focus on providing legal solutions to meet client needs, expectations and realities.
                                        Client satisfaction is the ultimate goal of our legal practice.  
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='py-6'>
                            <ValueSection />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Values