import React from 'react'

const Stats = () => {
    return (
        <>
            <div className="container mx-auto">
                <section className="container bg-[#FFFFFF] lg:py-8 md:py-6 py-12 lg:px-8">
                    <div className="p-8 mx-auto">
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-8">
                            <div className="rounded-lg ">
                                <div className="py-4 text-center lg:border-x border-[#121212] md:border-l sm:border-l leading-loose">
                                    <span className='font-semibold lg:text-5xl md:text-4xl text-3xl'> 400+ </span>
                                    <br /> 
                                    <span> Client Consultations </span>
                                </div>
                            </div>
                            <div className="rounded-lg ">
                                <div className="py-4 text-center lg:border-x border-[#121212] md:border-l sm:border-l leading-loose">
                                    <span className='font-semibold lg:text-5xl md:text-4xl text-3xl'> 95% </span>
                                    <br /> 
                                    <span> Successful Cases </span>
                                </div>
                            </div>
                            <div className="rounded-lg ">
                                <div className="py-4 text-center lg:border-x border-[#121212] md:border-l sm:border-l leading-loose">
                                    <span className='font-semibold lg:text-5xl md:text-4xl text-3xl'> 5m </span>
                                    <br /> 
                                    <span> Recovered Costs </span>
                                </div>
                            </div>
                            <div className="rounded-lg ">
                                <div className="py-4 text-center lg:border-x border-[#121212] md:border-l sm:border-l leading-loose">
                                    <span className='font-semibold lg:text-5xl md:text-4xl text-3xl'> 4+ </span>
                                    <br /> 
                                    <span> Advocacy Years </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Stats