import React from "react";
import { Link } from "react-router-dom";

const ResourcesPage = () => {
    return (
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                <div className="lg:col-span-3 space-y-8">
                    <section id="legal-articles" className="bg-[FFFFFF] p-6 rounded shadow">
                        <h2 className="text-2xl font-bold py-4">Legal Articles and Blog Posts</h2>
                        <article className="py-4">
                            <h3 className="text-xl font-semibold">Kenyan Legal System Overview</h3>
                            <p>
                                A detailed guide explaining the structure and functions of the legal system in Kenya.
                                <Link to="/articles/kenyan-legal-system" className="text-[#060C82] hover:underline"> Read more </Link>
                            </p>
                        </article>
                        <article className="py-4">
                            <h3 className="text-xl font-semibold">Recent Legal Updates</h3>
                            <p>
                                Articles covering recent changes and updates in Kenyan law.
                                <Link to="/articles/recent-legal-updates" className="text-[#060C82] hover:underline"> Read more </Link>
                            </p>
                        </article>
                        <article>
                            <h3 className="text-xl font-semibold">Practice Area Insights</h3>
                            <p>
                                In-depth articles and posts about various legal practice areas.
                                <Link to="/articles/practice-area-insights" className="text-[#060C82] hover:underline"> Read more </Link>
                            </p>
                        </article>
                    </section>

                    <section id="faqs" className="bg-white p-6 rounded shadow">
                        <h2 className="text-2xl font-bold py-4">Frequently Asked Questions</h2>
                        <div className="py-4">
                            <h3 className="text-xl font-semibold">General Legal Questions</h3>
                            <p>
                                Answers to common legal questions that clients might have.
                                <Link to="/faqs/general-legal-questions" className="text-[#060C82] hover:underline"> Read more </Link>
                            </p>
                        </div>
                        <div className="py-4">
                            <h3 className="text-xl font-semibold">Specific Practice Areas</h3>
                            <p>
                                FAQs related to each practice area offered by the firm.
                                <Link to="/faqs/practice-areas" className="text-[#060C82] hover:underline"> Read more </Link>
                            </p>
                        </div>
                        <div className="py-4">
                            <h3 className="text-xl font-semibold">Legal Process FAQs</h3>
                            <p>
                                Detailed information about what clients can expect during different legal processes.
                                <Link to="/faqs/legal-process" className="text-[#060C82] hover:underline"> Read more </Link>
                            </p>
                        </div>
                    </section>

                    <section id="legal-forms" className="bg-[#FFFFFF] p-6 rounded shadow">
                        <h2 className="text-2xl font-bold py-4"> Legal Forms and Templates </h2>
                        <div className="py-4">
                            <h3 className="text-xl font-semibold"> Standard Legal Documents </h3>
                            <p>
                                Downloadable templates for common legal documents.
                                <Link to="/forms/legal-documents" className="text-[#060C82] hover:underline"> Download </Link>
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold">Form Submissions</h3>
                            <p>
                                Online forms for potential clients to submit inquiries, book consultations, or request more information.
                                <Link to="/forms/submissions" className="text-[#060C82] hover:underline"> Submit </Link>
                            </p>
                        </div>
                    </section>

                    <section id="external-resources" className="bg-[#FFFFFF] p-6 rounded shadow">
                        <h2 className="text-2xl font-bold py-4">External Legal Resources</h2>
                        <ul className="list-disc pl-5">
                            <li className="py-2">
                                <a href="http://kenyalaw.org/" target="_blank" rel="noopener noreferrer" className="text- [#060C82] hover:underline">
                                    Kenya Law Reports
                                </a>
                            </li>
                            <li className="py-2">
                                <a href="https://lsk.or.ke/" target="_blank" rel="noopener noreferrer" className="text- [#060C82] hover:underline">
                                    Law Society of Kenya
                                </a>
                            </li>
                            <li className="py-2">
                                <a href="https://www.judiciary.go.ke/" target="_blank" rel="noopener noreferrer" className="text- [#060C82] hover:underline">
                                    Judiciary of Kenya
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ResourcesPage;
