import React from 'react'
import MenuPA from '../components/practiceareas/MenuPA'
import ItemsPA from '../components/practiceareas/ItemsPA'

const PracticeAreas = () => {
  return (
    <>
      <div>
        <MenuPA />
        <ItemsPA />
      </div>
    </>
  )
}

export default PracticeAreas