import React from 'react';

const ContactForm = () => {
    return (
        <>
            <div className='py-8'>
            <div className="bg-[#FFFFFF] min-h-screen flex items-center justify-center">
            <div className="w-full max-w-md p-8 bg-white">
                <h2 className="text-2xl font-bold text-center text-[#060C82]">Leave us your info</h2>
                <p className="text-center text-[#060C82] mb-8">and we will get back to you.</p>
                <form className="space-y-4">
                    <div>
                        <label className="block text-[#060C82] font-medium mb-1">Full Name</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#060C82]"
                            placeholder="Full Name"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-[#060C82] font-medium mb-1">Email</label>
                        <input
                            type="email"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#060C82]"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-[#060C82] font-medium mb-1">Subject</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#060C82]"
                            placeholder="Subject"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-[#060C82] font-medium mb-1">Message</label>
                        <textarea
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#060C82]"
                            placeholder="Message"
                            required
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="w-full px-4 py-2 bg-[#060C82] text-white font-bold rounded-lg hover:bg-[#222AC0] focus:outline-none focus:bg-[#a0875f]"
                        >
                            SUBMIT NOW
                        </button>
                    </div>
                </form>
            </div>
        </div>
            </div>
        </>
    );
};

export default ContactForm;
