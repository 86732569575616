import React from "react";

import arbitration from "../assets/arbitration.jpg";
import banking from "../assets/banking.jpg";
import realestate from "../assets/realestate.jpg";
import corporate from "../assets/corporate.jpg";
import tax from "../assets/tax.jpg";
import labor from "../assets/labor.jpg";

const ItemsPA = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="lg:px-16 md:px-8 sm:px-4 lg:py-16 md:py-12 sm:py-8">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 md:grid-cols-2 md:gap-4 p-4">
                        <div className="rounded-lg py-8 bg-[#F5F4EE]">
                            <div className="p-4 grid justify-items-center">
                                <img
                                    src={arbitration}
                                    className="px-4 rounded"
                                    alt="Arbitration"
                                    loading="lazy"
                                />
                            </div>
                            <div className="p-4">
                                <h1 className="text-center text-xl font-bold text-[#121212]">
                                    Arbitration & Dispute Resolution
                                </h1>
                            </div>
                            <div className="p-4 text-center font-medium">
                                Our expert Arbitration & Dispute Resolution services ensure
                                swift and fair settlements for complex legal conflicts.
                            </div>
                        </div>
                        <div className="rounded-lg py-8 bg-[#F5F4EE]">
                            <div className="p-4 grid justify-items-center">
                                <img
                                    src={banking}
                                    className="px-4 rounded"
                                    alt="Banking"
                                    loading="lazy"
                                />
                            </div>
                            <div className="p-4">
                                <h1 className="text-center text-xl font-bold text-[#121212]">

                                    Banking & Investment
                                </h1>
                            </div>
                            <div className="p-4 text-center font-medium">
                                We provide comprehensive legal guidance in Banking & Investment
                                to secure and grow your financial ventures.
                            </div>
                        </div>
                        <div className="rounded-lg py-8 bg-[#F5F4EE]">
                            <div className="p-4 grid justify-items-center">
                                <img
                                    src={corporate}
                                    className="px-4 rounded"
                                    alt="Corporate"
                                    loading="lazy"
                                />
                            </div>
                            <div className="p-4">
                                <h1 className="text-center text-xl font-bold text-[#121212]">

                                    Corporate & Commercial
                                </h1>
                            </div>
                            <div className="p-4 text-center font-medium">
                                Our Corporate & Commercial law expertise helps businesses
                                navigate regulations and achieve their commercial goals.
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 md:grid-cols- md:gap-4 p-4">
                        <div className="rounded-lg py-8 bg-[#F5F4EE]">
                            <div className="p-4 grid justify-items-center">
                                <img
                                    src={realestate}
                                    className="px-4 rounded"
                                    alt="RealEstate"
                                    loading="lazy"
                                />
                            </div>
                            <div className="p-4">
                                <h1 className="text-center text-xl font-bold text-[#121212]">

                                    Conveyance & Real Estate
                                </h1>
                            </div>
                            <div className="p-4 text-center font-medium">
                                Trust our Conveyance and Real Estate legal services for seamless
                                property transactions and dispute resolutions.
                            </div>
                        </div>
                        <div className="rounded-lg py-8 bg-[#F5F4EE]">
                            <div className="p-4 grid justify-items-center">
                                <img
                                    src={labor}
                                    className="px-4 rounded"
                                    alt="Labor"
                                    loading="lazy"
                                />
                            </div>
                            <div className="p-4">
                                <h1 className="text-center text-xl font-bold text-[#121212]">

                                    Employment & Labor
                                </h1>
                            </div>
                            <div className="p-4 text-center font-medium">
                                We specialize in Employment & Labor law, protecting your rights
                                and fostering fair workplace practices.
                            </div>
                        </div>
                        <div className="rounded-lg py-8 bg-[#F5F4EE]">
                            <div className="p-4 grid justify-items-center">
                                <img
                                    src={tax}
                                    className="px-4 rounded"
                                    alt="tax"
                                    loading="lazy"
                                />
                            </div>
                            <div className="p-4">
                                <h1 className="text-center text-xl font-bold text-[#121212]">

                                    Tax
                                </h1>
                            </div>
                            <div className="p-4 text-center font-medium">
                                Our Tax law services offer strategic advice to optimize your tax
                                obligations and ensure compliance with regulations.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ItemsPA;
