import React from 'react'

import arbitration from '../assets/arbitration.jpg'
import banking from '../assets/banking.jpg'
import realestate from '../assets/realestate.jpg'
import corporate from '../assets/corporate.jpg'
import tax from '../assets/tax.jpg'
import labor from '../assets/labor.jpg'

const PracticeItems = () => {
    return (
        <>
            <div className="py-12">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-8 ">
                    <div className="rounded-lg">
                        <div className="py-4">
                            <div className="relative grid justify-items-center hover:scale-105 transition-transform duration-200">
                                <img className='h-64 w-full rounded-md object-cover' src={arbitration} alt="dispute" loading="lazy" />
                                <h2 className="absolute bottom-0 w-full text-xl text-center text-[#FFFFFF] font-medium bg-[#060C82] rounded-b-md py-2">Arbitration & Dispute Resolution</h2>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg">
                        <div className="py-4">
                            <div className="relative grid justify-items-center hover:scale-105 transition-transform duration-200">
                                <img className='h-64 w-full rounded-md object-cover' src={banking} alt="dispute" loading="lazy" />
                                <h2 className="absolute bottom-0 w-full text-xl text-center text-[#FFFFFF] font-medium bg-[#060C82] rounded-b-md py-2">Banking & Investment</h2>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg">
                        <div className="py-4">
                            <div className="relative grid justify-items-center hover:scale-105 transition-transform duration-200">
                                <img className='h-64 w-full rounded-md object-cover' src={corporate} alt="dispute" loading="lazy" />
                                <h2 className="absolute bottom-0 w-full text-xl text-center text-[#FFFFFF] font-medium bg-[#060C82] rounded-b-md py-2">Corporate & Commercial</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-8 py-4">
                    <div className="rounded-lg">
                        <div className="py-4">
                            <div className="relative grid justify-items-center hover:scale-105 transition-transform duration-200">
                                <img className='h-64 w-full rounded-md object-cover' src={realestate} alt="dispute" loading="lazy" />
                                <h2 className="absolute bottom-0 w-full text-xl text-center text-[#FFFFFF] font-medium bg-[#060C82] rounded-b-md py-2">Conveyance & Real Estate</h2>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg">
                        <div className="py-4">
                            <div className="relative grid justify-items-center hover:scale-105 transition-transform duration-200">
                                <img className='h-64 w-full rounded-md object-cover' src={labor} alt="dispute" loading="lazy" />
                                <h2 className="absolute bottom-0 w-full text-xl text-center text-[#FFFFFF] font-medium bg-[#060C82] rounded-b-md py-2">Employment & Labor</h2>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg">
                        <div className="py-4">
                            <div className="relative grid justify-items-center hover:scale-105 transition-transform duration-200">
                                <img className='h-64 w-full rounded-md object-cover ' src={tax} alt="dispute" loading="lazy" />
                                <h2 className="absolute bottom-0 w-full text-xl text-center text-[#FFFFFF] font-medium bg-[#060C82] rounded-b-md py-2">Tax</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PracticeItems
