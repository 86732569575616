import React from 'react'
import Hero from '../components/home/Hero'
import FeatureAreas from '../components/home/FeatureAreas'
import CtaOne from '../components/home/CtaOne'
import CtaTwo from '../components/home/CtaTwo'
import Stats from '../components/home/Stats'
import Values from '../components/home/Values'

const Home = () => {
  return (
    <div>
      <Hero />
      <CtaOne />
      <FeatureAreas />
      <Stats />
      <Values />
      <CtaTwo />
    </div>
  )
}

export default Home