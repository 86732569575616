import React from 'react'
import MenuC from '../components/contact/MenuC.jsx'
import ContactForm from '../components/contact/ContactForm.jsx'
import CtaCont from '../components/contact/CtaCont.jsx'

const Contact = () => {
  return (
    <>
      <div>
        <MenuC />
        <CtaCont />
        <ContactForm />
      </div>
    </>
  )
}

export default Contact