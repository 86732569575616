import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const CtaCont = () => {
    const latitude = -1.2832779954244227;
    const longitude = 36.990801792631466;
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

    return (
        <>
            <div className="container mx-auto bg-[#060C82] text-[#FFFFFF] p-4 sm:p-6 lg:p-8">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-8">
                    <div className="rounded-lg bg-[#1a237e] p-4">
                        <div className="font-medium grid justify-items-center">
                            <div className="py-4">
                                <FaPhoneAlt className="size-8" />
                            </div>
                            <div className="py-4 text-xl">
                                Our Contact
                            </div>
                            <div className="py-4 text-xl">
                                <a href="tel:+254795661888"> +254 795 661 888 </a> <br />
                                <a href="tel:+254716976001"> +254 716 976 001 </a>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg bg-[#1a237e] p-4">
                        <div className="font-medium grid justify-items-center">
                            <div className="py-4">
                                <FaEnvelope className="size-8" />
                            </div>
                            <div className="py-4 text-xl">
                                Our Email
                            </div>
                            <div className="py-4 text-xl">
                                <a href="mailto:info@gracekimaniadvocates.co.ke">info@gracekimaniadvocates.co.ke</a> <br />
                                <a href="mailto:gk@gracekimaniadvocates.co.ke">gk@gracekimaniadvocates.co.ke</a>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg bg-[#1a237e] p-4">
                        <div className="font-medium grid justify-items-center">
                            <div className="py-4">
                                <FaMapMarkerAlt className="size-8" />
                            </div>
                            <div className="py-4 text-xl">
                                Our Location
                            </div>
                            <div className="py-4 text-xl">
                                <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">Githunguri, Nairobi</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CtaCont;
