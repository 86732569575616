import React from 'react';
import { Link } from 'react-router-dom';

const CtaOne = () => {
    return (
        <div className="container mx-auto bg-[#F5F4EE]">
            <div className="py-8">
                <div className="px-4">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <div className="text-xl font-semibold text-[#121212] py-4 lg:px-16 md:px-8 text-center md:text-left">
                            Schedule your consultation
                        </div>
                        <div className="py-4 lg:px-20 md:px-8 text-center md:text-left">
                            <Link 
                                to='/contact' 
                                className="px-8 py-2 uppercase font-semibold hover:bg-[#FFFFFF] hover:text-[#121212] hover:border-2 hover:border-[#121212] bg-[#121212] text-[#FFFFFF] block md:inline-block"
                            >
                                Make Appointment
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CtaOne;
