import React from 'react'
import MenuR from '../components/resources/MenuR.jsx'
import ResourcesPage from '../components/resources/ResourcesPage.jsx'


const Resources = () => {
  return (
    <>
        <div>
            <MenuR />
            <ResourcesPage />
        </div>
    </>
  )
}

export default Resources