import React from 'react'
import { Link } from 'react-router-dom'

import PracticeItems from '../home/PracticeItems'


const FeatureAreas = () => {
  return (
    <>
      <div className="container mx-auto">
        <section className="container bg-[#FFFFFF] lg:py-20 md:py-12 sm:py-8 px-8">
          <div className="md:p-6 sm:p-4 mx-auto">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
              <div className="py-4 sm:p-4 rounded-lg ">
                <div className=" py-2">
                  <h2 className="text-2xl lg:text-4xl md:text-3xl font-bold border-b-4 border-b-[#060C82] text-[#121212]">
                    Practice Areas.
                  </h2>
                </div>
              </div>
              <div className="rounded-lg lg:col-span-2">
                <div className=" space-x-6 py-2">
                  <p className="text-xl">
                    Our clients come from different sectors including,
                    financial services, audit firms, manufacturing, pharmaceutical, real estate, telecommunications and media services.
                  </p>
                </div>
              </div>
            </div>
            <PracticeItems />
            <div className="relative grid justify-items-center">
              <Link
                to="/practiceareas"
                className="px-8 py-2 uppercase text-center font-semibold hover:bg-[#FFFFFF] hover:text-[#121212] hover:border-2 hover:border-[#121212] bg-[#121212] text-[#FFFFFF]"
              >
                More
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default FeatureAreas