import React from 'react'
import { Link } from 'react-router-dom';

import logo from '../assets/logo.svg'


const Hero = () => {
    return (
        <>
            <div className="container mx-auto">
                <section className="container bg-[#FFFFFF] py-20 px-8">
                    <div className="lg:p-8 md:p-4 sm:px-4 mx-auto">
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 place-content-content lg:gap-8">
                            <div className=" grid justify-items-center ">
                                <img className='h-80 w-80 rounded-md' src={logo} alt="Grace Logo" />
                            </div>
                            <div className="lg:py-8 sm:py-4 lg:px-2 sm:px-2 rounded-lg ">
                                <div className="lg:p-6 md:p-6 sm:p-4">
                                    <h1 className="lg:text-4xl md:text-3xl text-xl font-bold uppercase leading-normal"> Grace Kimani And <br /> Company Advocates </h1>
                                </div>
                                <div className="lg:px-6 sm:px-4 py-4">
                                    <p className="leading-normal font-medium text-xl">
                                    A full-service law firm based in Nairobi, Kenya. <br /> We offer high-quality legal services and solutions to clients.
                                    </p>
                                </div>
                                <div className="lg:px-6 sm:px-4 py-4">
                                    <Link to = '/contact' className="px-8 py-2 uppercase text-center font-semibold hover:bg-[#FFFFFF] hover:text-[#121212] hover:border-2 hover:border-[#121212] bg-[#121212] text-[#FFFFFF] ">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Hero