import React from 'react'
import MenuAU from '../components/about/MenuAU.jsx'

const About = () => {
  return (
    <>
      <div>
        <MenuAU />
      </div>
    </>
  )
}

export default About