import React from "react";
import logo_icon from "./assets/logo_icon.jpg";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaClock,
  FaMapMarkerAlt,
  FaPaperPlane,
} from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="bg-[#060C82] text-white p-6 flex flex-col md:flex-row md:justify-between md:items-start md:gap-x-6 md:gap-y-12">
        <div className="mb-6 md:mb-0 py-6 md:py-12 flex flex-col items-start">
          <div className="flex items-center">
            <Link to="/">
              <img src={logo_icon} alt="Company Logo" className="h-16" />
            </Link>
          </div>
        </div>
        <div className="mb-6 md:mb-0 py-6 md:py-12 flex flex-col items-start">
          <h2 className="text-2xl sm:text-3xl font-semibold">Contact Info</h2>
          <p className="mt-2 flex items-center">
            <FaPhoneAlt className="mr-2" />
            <a href="tel:+1234657890" className="text-[#FFFFFF] font-medium">
            +254 795 661 888
            </a>
          </p>
          <p className="mt-2 flex items-center">
            <FaEnvelope className="mr-2" /> info@gracekimaniadvocates.co.ke
          </p>
          <p className="mt-2 flex items-center">
            <FaClock className="mr-2" /> Mon – Fri 9.00-19.00
          </p>
          <p className="mt-2 flex items-center">
            <FaMapMarkerAlt className="mr-2" /> Githunguri, Nairobi
          </p>
        </div>
        <div className="mb-6 md:mb-0 py-6 md:py-12 flex flex-col items-start">
          <h2 className="text-2xl sm:text-3xl font-semibold">Useful Links</h2>
          <ul className="mt-2 flex flex-col items-start">
            <li className="mt-2">
              <Link to="/practiceareas"> Practice Areas </Link>
            </li>
            <li className="mt-2">
              <Link to="/resources"> Resources </Link>
            </li>
            <li className="mt-2">
              <Link to="/about"> About Us </Link>
            </li>
          </ul>
        </div>
        <div className="mb-6 md:mb-0 py-6 md:py-12 flex flex-col items-start">
          <h2 className="text-2xl sm:text-3xl font-semibold">Newsletter</h2>
          <p className="mt-2 text-left">Subscribe to our newsletter</p>
          <form className="flex mt-2 w-full sm:w-auto">
            <input
              type="email"
              placeholder="Enter Your Email Address"
              className="p-2 flex-1 rounded-l-md"
            />
            <button
              type="submit"
              className="bg-white text-[#060C82] p-2 rounded-r-md"
            >
              <FaPaperPlane />
            </button>
          </form>
        </div>
      </footer>
      <div className="px-12 text-center text-[#FFFFFF] bg-[#060C82] py-4 text-xl">
        © {currentYear} Grace Kimani & Company Advocates
        <p className="py-4">
          {" "}
          A{" "}
          <a
            href="https://www.batiantechnologies.com"
            className="font-semibold underline"
            target="blank"
          >
            Batian Technologies
          </a>{" "}
          Website
        </p>
      </div>
    </>
  );
};

export default Footer;
