import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Cta = () => {
  return (
    <>
      <section className="lg:py-8 md:py-8 sm:py-8 mb-8 bg-[#060C82] ">
        <div className="container mx-auto">
          <div className="rounded bg-primary py-12 px-8 md:p-[70px]">
            <div className="flex flex-wrap items-center -mx-4">
              <div className="w-full px-4 lg:w-1/2">
              <h2 className="mb-6 text-3xl font-bold leading-tight text-white sm:mb-8 sm:text-[40px]/[48px] lg:mb-0">
                  <span className="xs:block"> Are you in need </span>
                  <span>of Legal Assistance ?</span>
                </h2>
                <span className="block mb-4 py-4 text-base font-medium text-white">
                  Reach out to us
                </span>
              </div>
              <div className="w-full px-4 lg:w-1/2">
                <div className="flex flex-wrap lg:justify-end">
                  <Link
                    to="/contact"
                    className="inline-flex py-3 my-1 mr-4 text-base font-medium transition bg-white rounded-md hover:bg-shadow-1 text-primary px-7"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
