import React from 'react'

import integrity from '../assets/integrity.png'
import competence from '../assets/competence.png'
import consistency from '../assets/consistency.png'
import accountability from '../assets/accountability.png'



const ValueSection = () => {
    return (
        <>
            <div className="container mx-auto">
                <section className="container bg-[#FFFFFF] lg:py-20 md:py-12 sm:py-8 px-4">
                    <div className="md:p-6 sm:p-4 mx-auto">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-2 md:grid-cols-2 md:gap-6 lg:grid-cols-4 lg:gap-8">
                            <div className="p-4 rounded-lg text-center">
                                <img className="h-40 mx-auto" src={integrity} alt="integrity" loading="lazy" />
                                <p className="text-xl py-4 font-semibold">Integrity</p>
                            </div>
                            <div className="p-4 rounded-lg text-center">
                                <img className="h-40 mx-auto" src={competence} alt="competence" loading="lazy" />
                                <p className="text-xl py-4 font-semibold">Competence</p>
                            </div>
                            <div className="p-4 rounded-lg text-center">
                                <img className="h-40 mx-auto" src={accountability} alt="accountability" loading="lazy" />
                                <p className="text-xl py-4 font-semibold">Accountability</p>
                            </div>
                            <div className="p-4 rounded-lg text-center">
                                <img className="h-40 mx-auto" src={consistency} alt="consistency" loading="lazy" />
                                <p className="text-xl py-4 font-semibold">Consistency</p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
}

export default ValueSection