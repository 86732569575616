import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu, MdClose, MdPhone } from 'react-icons/md';
import gracelogo from './assets/gracelogo.jpg';

const navLinks = [
  { name: 'Practice Areas', path: '/practiceareas' },
  { name: 'Resources', path: '/resources' },
  { name: 'About Us', path: '/about' },
  { name: 'Contact', path: '/contact' }
];

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="fixed top-0 w-full bg-[#FFFFFF] text-[#060C82] shadow-lg z-10">
      <div className="container mx-auto py-4 px-6 flex items-center justify-between">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img className="h-12 sm:h-16" src={gracelogo} alt="logo" />
        </Link>

        {/* Mobile Menu Toggle Button */}
        <button
          className="md:hidden text-[#060C82]"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          {menuOpen ? <MdClose className="h-10 w-10" /> : <MdMenu className="h-10 w-10" />}
        </button>

        {/* Mobile Menu - Slides in from left */}
        <div className={`fixed left-0 top-0 h-full w-64 bg-[#FFFFFF] shadow-lg md:hidden ${menuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
          <div className="flex flex-col items-start space-y-4 mt-4 text-xl font-bold py-4 px-8">
            {/* Logo in Mobile Menu */}
            <Link to="/" className="flex items-center">
              <img className="h-12 sm:h-16" src={gracelogo} alt="logo" />
            </Link>

            {/* Navigation Links in Mobile Menu */}
            {navLinks.map((link, index) => (
              <Link
                key={index}
                to={link.path}
                className="text-[#060C82] hover:text-[#1D24AE] text-lg px-6 py-3"
                onClick={toggleMenu}
              >
                {link.name}
              </Link>
            ))}
            
            {/* Contact Information in Mobile Menu */}
            <div className="flex items-center">
              <MdPhone className="text-[#060C82] mr-2" size={24} />
              <p><a href="tel:+1234657890" className="text-[#060C82]">+254 795 661 888</a></p>
            </div>
          </div>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex flex-1 justify-end items-center space-x-8 text-lg font-bold">
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className="text-[#060C82] hover:text-[#1D24AE]"
            >
              {link.name}
            </Link>
          ))}
          <div className="flex items-center">
            <MdPhone className="text-[#060C82] mr-2" size={24} />
            <p><a href="tel:+1234657890" className="text-[#060C82]">+254 795 661 888</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
